var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.showLoader)?_c('div',[_c('loader')],1):_c('div',[_c('vue-progress-bar'),(
        _vm.$route.path === '/login' ||
        _vm.$route.path === '/register' ||
        _vm.$route.path === '/forget' ||
        _vm.$route.path === '/verify' ||
        _vm.$route.name === 'register-invite' ||
        _vm.$route.name === 'not-activated'
      )?_c('main',[_c('auth-layout')],1):_c('main',[(_vm.mobileView)?_c('main',[_c('mobile-layout')],1):_c('main',[_c('main-layout')],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }