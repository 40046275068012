import { render, staticRenderFns } from "./currency-modal.vue?vue&type=template&id=123fb240&scoped=true&"
import script from "./currency-modal.vue?vue&type=script&lang=js&"
export * from "./currency-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123fb240",
  null
  
)

export default component.exports